.trazabilidad-home-container {
  width: 420px;
  height: 320px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.trazabilidad-home-wrappper {
  width: 200px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #64a70b;
  border-radius: 8px;
  padding: 10px;
}

.trazabilidad-home-button {
  width: 100%;
  height: 100%;
  border: 0;
  display: flex;
  align-items: center;
  background-color: #64a70b;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #fff;
}

.trazabilidad-home-button:disabled {
  cursor: not-allowed;
}

.trazabilidad-home-button:hover {
  background-color: #5c9a0b;
}

.trazabilidad-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
}

.trazabilidad-title-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.trazabilidad-form-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: auto;
  padding: 20px;
}

.trazabilidad-file-uploader-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
}

.trazabilidad-file-uploader {
  max-width: 100% !important;
  width: 100%;
}

.trazabilidad-file-uploader-file-list-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.trazabilidad-file-uploader-file-list-title {
  color: #4f4f4f;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin: 0;
}

.trazabilidad-file-uploader-file-list-item {
  color: #4f4f4f;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.trazabilidad-file-uploader-file-list-item-remove {
  color: red;
  cursor: pointer;
}

.trazabilidad-multi-form {
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.trazabilidad-multi-form-row {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.trazabilidad-multi-form-row-error {
  z-index: 9999;
  position: absolute;
  bottom: -100%;
}

.trazabilidad-multi-form-row-fields-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.trazabilidad-multi-form-row-fields-container > div {
  flex: 1;
}

.trazabilidad-multi-form-row-action-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.trazabilidad-dashboard-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.trazabilidad-dashboard-card-container {
  display: flex;
  padding: 0 20px;
  gap: 10px;
}

.trazabilidad-dashboard-card {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px;
  width: 120px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-family: Roboto;
  font-style: normal;
  line-height: normal;
  color: #000;
  border: 2px solid;
}

.trazabilidad-dashboard-card > h2 {
  margin: 0;
  font-size: 14px;
  font-weight: 300;
  text-align: center;
}

.trazabilidad-dashboard-card > p {
  margin: 0;
  font-size: 12px;
  font-weight: 300;
  text-align: center;
}
