.envases-and-pallet-compliance-container {
  display: flex;
  flex-direction: column;
}

.envases-and-pallet-compliance-filters-section {
  padding: 20px 20px 0 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.envases-and-pallet-compliance-filters-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 20px;
}

.envases-and-pallet-compliance-date-modal-container {
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 350px;
  border: 1px solid #000;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.envases-and-pallet-compliance-date-modal-header-container {
  display: flex;
  justify-content: flex-end;
}

.envases-and-pallet-compliance-date-modal-chip-container {
  display: flex;
  gap: 5px;
  align-items: baseline;
  flex-wrap: wrap;
  justify-content: center;
}

.envases-and-pallet-compliance-red-dot {
  width: 15px;
  height: 15px;
  border-radius: 10px;
  background-color: red;
}

.envases-and-pallet-compliance-green-dot {
  width: 15px;
  height: 15px;
  border-radius: 10px;
  background-color: green;
}
